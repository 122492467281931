<template>
    <div class="d-flex heading vert-center">
        <h5>
            {{ $t('page.ordersHistory.listing.orderNumberText') }}
            <span>#{{ orderDetail.id }}</span>
        </h5>
        <p
            class="sbold status"
            :class="orderDetail.status.value"
        >
            {{ orderDetail.status.label }}
        </p>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'OrderDetailHeading',
        computed: {
            ...mapState('order', ['orderDetail']),
        },
    };
</script>
<style scoped lang="scss">
.heading {
  margin-bottom: 24px;
  gap: 24px;
  @include media-breakpoint-down(sm) {
    justify-content: space-between;
  }
  h5 {
    span {
      color: $text;
    }
  }
  .status {
    border: 1px solid $secondary;
    color: $secondary;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 16px 0px 16px;
    border-radius: 4px;
    letter-spacing: 1px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      max-width: 150px;
    }
  }
  p {
    color: $text;
  }
}
</style>
