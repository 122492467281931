<template>
    <div class="detail-general">
        <div class="d-flex vert-center">
            <p class="sbold">
                {{ $t('page.ordersHistory.listing.placedOnText') }}
                {{ orderDetail.order_placed_date }}
            </p>
            <p
                v-if="!isLowScreen"
                class="separator"
            >
                |
            </p>
            <p
                v-dompurify-html="formatPrice(orderDetail.total)"
                class="has-sup sbold"
            />
        </div>
        <div class="d-flex space-between vert-center">
            <span>
                {{
                    $t('page.ordersHistory.details.deliveryMethod', {
                        name: orderDetail.shipping_method,
                    })
                }}
            </span>
        </div>
        <div class="d-flex space-between vert-center">
            <span
                v-if="orderDetail.awb"
                class="track d-flex vert-center"
            >
                {{ $t('page.ordersHistory.details.awbText') }}
                <a
                    :href="orderDetail.tracking_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary stroke sm"
                >
                    <eye-icon class="sm primary" />
                    {{ orderDetail.awb }}</a>
            </span>
        </div>
    </div>
</template>
<script>
    import EyeIcon from 'vue-feather-icons/icons/EyeIcon';
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';
    export default {
        name: 'OrderDetailGeneral',
        components: {
            EyeIcon,
        },
        props: {
            isLowScreen: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('order', ['orderDetail']),
        },
        methods: {
            formatPrice(price) {
                return this.$t('page.ordersHistory.details.totalText', {
                    number: splitedPrice(price),
                });
            },
        },
    };
</script>
<style scoped lang="scss">
.detail-general {
  align-items: center;
  > div {
    &:first-of-type {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: initial;
        gap: 0;
        margin-bottom: 8px;
      }
    }
    p {
      color: $text;
    }
    .track {
      margin-top: 8px;
      justify-content: center;
      color: $text;
      font-weight: 600;
      .btn {
        margin: 0;
        height: 24px;
      }
    }
  }
}
</style>
