<template>
    <div class="d-flex downloads">
        <a
            v-if="orderDetail.invoice"
            :href="orderDetail.invoice"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-primary stroke sm"
        >
            <img
                src="/images/fe_download_blue.svg"
                :alt="$t('page.catalogue.downloadText')"
            >
            {{ $t('page.ordersHistory.details.invoiceText') }}
        </a>
        <a
            v-if="orderDetail.proforma"
            :href="orderDetail.proforma"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-primary stroke sm"
        >
            <img
                src="/images/fe_download_blue.svg"
                :alt="$t('page.catalogue.downloadText')"
            >
            {{ $t('page.ordersHistory.details.proformaText') }}
        </a>
        <a
            v-if="orderDetail.refund"
            :href="orderDetail.refund"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-primary stroke sm"
        >
            <img
                src="/images/fe_download_blue.svg"
                :alt="$t('page.catalogue.downloadText')"
            >
            {{ $t('page.ordersHistory.details.refundText') }}
        </a>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'OrderDetailDownloads',
        computed: {
            ...mapState('order', ['orderDetail']),
        },
    };
</script>
<style scoped lang="scss">
.downloads {
  flex-direction: column;
  a {
    margin: 0;
    letter-spacing: 1px;
    font-size: 12px;
    &.btn {
      height: 24px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 10px;
      letter-spacing: 2px;
    }
  }
}
</style>
