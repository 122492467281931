<template>
    <div class="list products">
        <div
            v-for="product in orderDetail.order_items.items.products"
            :key="`item-product-${product.code}`"
            class="item"
        >
            <div class="details">
                <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                    <div class="image">
                        <img
                            :src="product.thumbnail"
                            :alt="product.name"
                        >
                    </div>
                </router-link>
                <div class="info">
                    <router-link
                        :to="{ name: 'Produs', params: { slug: product.slug } }"
                        class="title"
                    >
                        <p class="sbold">
                            {{ product.name }}
                        </p>
                    </router-link>

                    <rating-stars
                        :rating="{
                            value: product.rating,
                            counter: product.rating_counter,
                        }"
                        location="product"
                    />
                    <span
                        v-if="isLowScreen"
                        class="price"
                    >
                        <p
                            v-dompurify-html="formatPrice(product.total_price)"
                            class="has-sup sm-sbold"
                        /></span>
                    <b-button
                        v-if="isOrderCompleted"
                        variant="primary"
                        class="sm text"
                        @click="openEditReviewModal(product)"
                    >
                        {{ $t('page.ordersHistory.details.addReviewText') }}
                    </b-button>
                    <span
                        v-if="isLowScreen"
                        class="quantity"
                    ><p class="sm-sbold">
                        {{ product.quantity }}
                        {{ $t('page.ordersHistory.details.quantityText') }}
                    </p></span>
                </div>
            </div>
            <div
                v-if="!isLowScreen"
                class="actions"
            >
                <span class="price">
                    <p
                        v-dompurify-html="formatPrice(product.total_price)"
                        class="has-sup sm-sbold"
                    /></span>
                <span
                    class="quantity"
                ><p class="sbold">
                    {{ product.quantity }}
                    {{ $t('page.ordersHistory.details.quantityText') }}
                </p></span>
            </div>
        </div>
        <rating-modal
            v-if="showModal"
            :product-id="productId"
            :rating="rating"
            :is-edit="true"
        />
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';

    import RatingStars from '@/components/product/RatingStars';
    import { splitedPrice } from '@/services/getSplitedPrice';

    const RatingModal = () => import('@/components/modals/RatingModal');

    export default {
        name: 'OrderDetailProductListing',
        components: {
            RatingStars,
            RatingModal,
        },
        props: {
            isLowScreen: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                rating: 0,
                productId: 0,
                showModal: false,
            };
        },
        computed: {
            ...mapState('order', ['orderDetail']),
            ...mapState('product', ['modal']),
            isOrderCompleted(){
                return this.orderDetail.status.value == 'completed';
            }
        },
        methods: {
            ...mapMutations('product', ['setModal']),
            formatPrice(price) {
                return splitedPrice(price);
            },
            openEditReviewModal(product) {
                this.productId = parseInt(product.id);
                this.setModal({
                    show: true,
                    type: 'rating',
                });
            },
        },
        watch: {
            modal: {
                handler(modal) {
                    if (this.isOrderCompleted && modal.type == 'rating') {
                        this.showModal = modal.show;
                    }
                },
                deep: true,
            },
        },
    };
</script>
<style scoped lang="scss">
.list {
  padding: 0;
  border: none;
  .item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    position: relative;
    &:first-of-type {
      padding-top: 24px;
    }
    &:last-of-type {
      border: none;
    }
    .details {
      display: flex;
      gap: 16px;
      justify-content: center;
      @include media-breakpoint-up(sm) {
        justify-content: left;
        width: 75%;
      }
      .image {
        background-color: $gray-thin;
        height: 60px;
        width: 90px;
        border-radius: 4px;
        @include media-breakpoint-down(sm) {
          max-height: 60px;
        }
        img {
          object-fit: contain;
        }
      }
      .info {
        max-width: 400px;
        width: 100%;
        p {
          color: $text;
          font-weight: 600;

          &.sm-sbold {
            font-size: 12px;
            line-height: 18px;
            height: auto;
          }
        }
        .title {
          p {
            height: 40px;
            -webkit-line-clamp: 2;
            &:first-of-type {
              margin-bottom: 8px;
              @include media-breakpoint-down(sm) {
                height: initial;
              }
            }
          }
        }

        .btn {
          margin: 4px 0px 0px;
          font-size: 12px;
          padding: 0;
          color: $primary;
          background: none;
          height: auto;
          &:focus-visible,
          &:focus {
            box-shadow: none;
            border: none !important;
            outline: none;
          }
        }
        .rating {
          @include media-breakpoint-down(sm) {
            margin: 0;
          }
        }
        .quantity {
          @include media-breakpoint-down(sm) {
            position: absolute;
            right: 0;
            bottom: 14px;
            p {
              text-transform: uppercase;
              color: $gray-dark;
            }
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      @include media-breakpoint-up(sm) {
        width: 25%;
      }
      .price {
        height: initial;
      }
      span {
        p {
          color: $text;
        }
        &.quantity {
          p {
            color: $gray-dark;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
