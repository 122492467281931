<template>
    <div class="list vouchers">
        <h6 class="mb-3">
            {{ $t('page.myPromoCodes.heading') }}
        </h6>
        <div
            v-for="voucher in orderDetail.order_items.items.vouchers"
            :key="`item-voucher-${orderDetail.order_items.items.vouchers.indexOf(
                voucher
            )}`"
            class="item"
        >
            <div class="details">
                <div class="image voucher">
                    <router-link :to="{ name: 'CupoaneleMele' }">
                        <img
                            :src="voucher.thumbnail"
                            :alt="voucher.name"
                        >
                    </router-link>
                </div>
                <div class="title">
                    <router-link :to="{ name: 'CupoaneleMele' }">
                        <p class="sbold">
                            {{ voucher.name }}
                        </p>
                    </router-link>
                    <p
                        v-if="voucher.code"
                        class="sm-sbold"
                    >
                        {{ $t('page.myPromoCodes.codeText') }} {{ voucher.code }}
                    </p>
                    <p
                        v-if="voucher.ended_at"
                        class="sm-sbold"
                    >
                        {{
                            $t('page.myPromoCodes.expirationText', {
                                expiration: voucher.ended_at,
                            })
                        }}
                    </p>
                </div>
            </div>
            <div class="actions">
                <span
                    class="status"
                    :class="voucher.status.value"
                >{{ voucher.status.label }}
                </span>
                <span class="price">
                    <p
                        v-dompurify-html="formatPrice(voucher.value)"
                        class="has-sup sm-sbold"
                    /></span>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';
    export default {
        name: 'OrderDetailVoucherListing',
        computed: {
            ...mapState('order', ['orderDetail']),
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>

<style scoped lang="scss">
.list {
  padding: 0;
  border: none;
  h6 {
    color: $text;
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin: 0;
    padding-top: 16px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      row-gap: 12px;
    }
    &:first-of-type {
      border-top: 1px solid $gray-thin;
    }
    .details {
      display: flex;
      gap: 16px;
      justify-content: center;
      .image {
        background-color: $gray-thin;
        height: 60px;
        width: 90px;
        border-radius: 4px;
        @include media-breakpoint-down(sm) {
          max-height: 60px;
        }
        img {
          object-fit: contain;
        }
      }
      .title {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        a {
          font-weight: 600;
        }
        p {
          text-overflow: ellipsis;
          height: 24px;
          overflow: hidden;
          color: $text;
          -webkit-line-clamp: 1;
          @include media-breakpoint-down(sm) {
            height: 21px;
          }
          &.sm-sbold {
            font-size: 12px;
            line-height: 18px;
            height: auto;
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        flex-direction: row;
        align-items: initial;
        width: 100%;
      }
      .status {
        display: flex;
        align-items: center;
        border: 1px solid $primary;
        color: $primary;
        padding: 2px 16px 0px 16px;
        border-radius: 4px;
        letter-spacing: 1px;
        font-weight: 700;
        text-align: center;
        font-size: 10px;
        background-color: $white;
        text-transform: uppercase;
        &.enabled {
          border-color: $secondary;
          color: $secondary;
        }
        &.applied {
          border-color: $green;
          color: $green;
        }
        &.used {
          border-color: $text;
          color: $text;
        }
        &.expired {
          border-color: $red;
          color: $red;
        }
      }
      .price {
        height: initial;
        p {
          color: $text;
        }
      }
    }
  }
}
</style>
