<template>
    <div class="detail-info d-flex">
        <div class="detail-delivery">
            <p class="heading sbold">
                {{ $t('page.ordersHistory.details.deliveryHeading') }}
            </p>
            <div>
                <p class="sm">
                    {{ orderDetail.contact_name }}
                </p>
                <p class="sm">
                    {{ orderDetail.contact_county }}, {{ orderDetail.contact_city }},
                    {{ orderDetail.contact_address }}
                </p>
            </div>
        </div>
        <div class="detail-billing">
            <p class="heading sbold">
                {{ $t('page.ordersHistory.details.billingHeading') }}
            </p>
            <div>
                <p class="sm">
                    {{ billingName }}
                </p>
                <p class="sm">
                    {{ billingCounty }}, {{ billingCity }},
                    {{ billingAddress }}
                </p>
                <p
                    v-if="!isIndividual"
                    class="sm"
                >
                    {{ orderDetail.company_regnr }}
                </p>
                <p
                    v-if="!isIndividual"
                    class="sm"
                >
                    {{ orderDetail.company_cif }}
                </p>
                <p
                    v-if="!isIndividual"
                    class="sm"
                >
                    {{ orderDetail.company_bank }}
                </p>
                <p
                    v-if="!isIndividual"
                    class="sm"
                >
                    {{ orderDetail.company_account }}
                </p>
            </div>
        </div>
        <div class="detail-payment">
            <p class="heading sbold">
                {{ $t('page.ordersHistory.details.paymentHeading') }}
            </p>
            <div>
                <p class="sm mb-1">
                    {{ orderDetail.payment_method.label }}
                </p>
                <card-types v-if="orderDetail.payment_method.value == 'online'" />
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    import CardTypes from '@/components/checkout/CardTypes';
    export default {
        name: 'OrderDetailInfo',
        components: {
            CardTypes,
        },
        computed: {
            ...mapState('order', ['orderDetail']),
            isIndividual(){
                return  this.orderDetail['person_type'] == 'individual';
            },
            billingName(){
                return this.isIndividual ? this.orderDetail.billing_name : this.orderDetail.company_name;
            },
            billingCounty(){
                return this.isIndividual ? this.orderDetail.billing_county : this.orderDetail.company_county;
            },
            billingCity(){
                return this.isIndividual ? this.orderDetail.billing_city : this.orderDetail.company_city;
            },
            billingAddress(){
                return this.isIndividual ? this.orderDetail.billing_address : this.orderDetail.company_address;
            },
        },
    };
</script>
<style scoped lang="scss">
.detail-info {
  border: none;
  padding: 0;
  gap: 16px;
  @include media-breakpoint-down(sm) {
    padding: 16px 0px;
    flex-direction: column;
    gap: 0;
  }
  > div {
    width: 50%;
    padding: 16px 0;
    border-radius: 4px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .heading {
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-bottom: 12px;
      margin-bottom: 16px;
      border-bottom: 1px solid $gray-thin;
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 61px;
        justify-content: flex-end;
      }
    }
    > div {
      p {
        color: $text;
        max-width: 250px;
      }
    }
    &.detail-payment {
      div {
        p {
          color: $text;
        }
      }
    }
  }
}
</style>
