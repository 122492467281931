<template>
    <div class="detail-total d-flex mt-4">
        <div class="d-flex">
            <p class="heading sm-sbold">
                {{ $t('page.ordersHistory.details.priceText') }}
            </p>
            <p
                v-dompurify-html="
                    formatPrice(orderDetail.order_items.total_item_prices)
                "
                class="has-sup sm-sbold"
            />
        </div>
        <div
            v-if="orderDetail.discount_value"
            class="d-flex mt-1"
        >
            <p class="heading sm-sbold">
                {{
                    $t('page.ordersHistory.details.discountText', {
                        name: orderDetail.discount_name,
                    })
                }}
            </p>
            <p
                v-dompurify-html="formatPrice(-Math.abs(orderDetail.discount_value))"
                class="has-sup sm-sbold"
            />
        </div>
        <div class="d-flex mt-1">
            <p class="heading sm-sbold">
                {{ $t('page.ordersHistory.details.deliveryText') }}
            </p>
            <p
                v-dompurify-html="formatPrice(orderDetail.shipping_cost)"
                class="has-sup sm-sbold"
            />
        </div>
        <div
            v-if="orderDetail.extra_km_cost > 0"
            class="d-flex mt-1"
        >
            <p class="heading sm-sbold">
                {{
                    $t('page.ordersHistory.details.extraKmText', {
                        name: orderDetail.shipping_method,
                    })
                }}
            </p>
            <p
                v-dompurify-html="formatPrice(orderDetail.extra_km_cost)"
                class="has-sup sm-sbold"
            />
        </div>
        <div
            v-if="orderDetail.ramburs_fee > 0"
            class="d-flex mt-1"
        >
            <p class="heading sm-sbold">
                {{ $t('page.ordersHistory.details.rambursFeeText') }}
            </p>
            <p
                v-dompurify-html="formatPrice(orderDetail.ramburs_fee)"
                class="has-sup sm-sbold"
            />
        </div>
        <div class="d-flex mt-2">
            <p class="heading sbold">
                {{ $t('page.ordersHistory.details.totalText', { number: null }) }}
            </p>
            <p
                v-dompurify-html="formatPrice(orderDetail.total)"
                class="has-sup sbold"
            />
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';
    export default {
        name: 'OrderDetailTotal',
        computed: {
            ...mapState('order', ['orderDetail']),
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>
<style scoped lang="scss">
.detail-total {
  padding: 0;
  border: none;
  flex-direction: column;
  gap: 2px;
  > div {
    justify-content: space-between;
    gap: 12px;
    p {
      color: $text;
      &:last-of-type {
        min-width: max-content;
      }
    }
    &:last-of-type {
      padding-top: 8px;
      border-top: 1px solid $gray-thin;
      .heading {
        text-transform: uppercase;
      }
      p {
        color: $primary;
      }
    }
    .heading {
      letter-spacing: 1px;
    }
  }
}
</style>
