<template>
    <ul class="breadcrumb">
        <li>
            <p
                class="sm link"
                @click="goToPage()"
            >
                {{ $t('page.ordersHistory.details.breadcrumb.listingText') }} /
            </p>
        </li>
        <li>
            <p class="sm">
                {{
                    orderDetail
                        ? $t('page.ordersHistory.details.breadcrumb.detailsText')
                        : $t('page.ordersHistory.details.breadcrumb.noOrder')
                }}
            </p>
        </li>
    </ul>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        name: 'OrderDetailBreadcrumb',
        computed: {
            ...mapState('order', ['orderDetail']),
        },
        methods:{
            goToPage(){
                this.$router.push({ name: 'Comenzi' }).catch(() => {
                    //
                });
            }
        }
    };
</script>
<style scoped lang="scss">
.breadcrumb {
  margin-bottom: 24px;
}
</style>
