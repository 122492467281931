<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar />
            <b-col
                v-if="existData"
                cols="12"
                md="9"
                class="order-detail mtd-40"
                :class="{ transparent: showLoader }"
            >
                <order-detail-breadcrumb />
                <div class="heading">
                    <div class="left">
                        <order-detail-heading />
                        <order-detail-general :is-low-screen="isLowScreen" />
                    </div>
                    <div class="right">
                        <order-detail-downloads />
                    </div>
                </div>
                <order-detail-info />
                <order-detail-product-listing :is-low-screen="isLowScreen" />
                <order-detail-voucher-listing v-if="existVoucher" />
                <order-detail-total />
            </b-col>
            <b-col
                v-if="!loading && !existData"
                cols="12"
                md="9"
                class="order-detail mtd-40"
            >
                <order-detail-breadcrumb />
                <div class="d-flex heading vert-center">
                    <p class="lg-sbold">
                        {{ $t('page.ordersHistory.listing.noOrder') }}
                    </p>
                </div>
            </b-col>
        </b-row>
        <order-confirmation-modal
            v-if="showOrderConfirmationModal"
            :payment-status="status"
            :message="message"
            :attempt="attempt"
        />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import Sidebar from '@/components/page/Sidebar';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import OrderDetailBreadcrumb from './orderDetail/OrderDetailBreadcrumb';
    import OrderDetailDownloads from './orderDetail/OrderDetailDownloads';
    import OrderDetailGeneral from './orderDetail/OrderDetailGeneral';
    import OrderDetailHeading from './orderDetail/OrderDetailHeading';
    import OrderDetailInfo from './orderDetail/OrderDetailInfo';
    import OrderDetailProductListing from './orderDetail/OrderDetailProductListing';
    import OrderDetailTotal from './orderDetail/OrderDetailTotal';
    import OrderDetailVoucherListing from './orderDetail/OrderDetailVoucherListing';
    const OrderConfirmationModal = () =>
        import(
            /* webpackChunkName: "order-confirmation" */ '@/components/modals/OrderConfirmationModal'
        );
    export default {
        name: 'OrderHistory',
        data() {
            return {
                loading: false,
                screenWidth: 0,
                showOrderConfirmationModal: false,
                status: 'APPROVED',
                message: '',
                attempt: 0,
            };
        },
        components: {
            Sidebar,
            OrderDetailBreadcrumb,
            OrderDetailHeading,
            OrderDetailGeneral,
            OrderDetailDownloads,
            OrderDetailInfo,
            OrderDetailProductListing,
            OrderDetailVoucherListing,
            OrderDetailTotal,
            LoadingSpinner,
            OrderConfirmationModal,
        },
        computed: {
            ...mapState('order', ['orderDetail', 'paymentStatus']),
            ...mapState('user', ['modal']),
            lang() {
                return i18n.locale;
            },
            existData() {
                return Object.keys(this.orderDetail).length > 0;
            },
            showLoader() {
                return !this.existData && this.loading;
            },
            isLowScreen() {
                return this.screenWidth < 576;
            },
            existVoucher(){
                return this.orderDetail.order_items.items.vouchers.length > 0;
            }
        },
        methods: {
            ...mapActions('order', ['getOrderDetails', 'getPaymentStatus']),
            ...mapMutations('user', ['setModal']),
            async loadData() {
                this.loading = true;
                let { orderId } = this.$route.query;
                if (orderId) {
                    let data = {
                        orderId: this.$route.params.id,
                        paymentOrderId: orderId,
                    };
                    this.$router.replace({ path: this.$route.path, query: {} });
                    await this.getPaymentStatus(data);
                    if (
                        this.paymentStatus.status != '' &&
                        this.paymentStatus.message != ''
                    ) {
                        this.setModal({
                            show: true,
                            type: 'orderSuccess',
                        });
                    }
                }
                await this.getOrderDetails(this.$route.params.id);
                this.loading = false;
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
            initConfirmationModal() {
                if (this.modal.type == 'orderSuccess') {
                    this.showOrderConfirmationModal = this.modal.show;
                    this.status = this.paymentStatus.status;
                    this.message = this.paymentStatus.message;
                    this.attempt = this.paymentStatus.attempt;
                }
            },
        },
        created() {
            this.loadData();
            this.initConfirmationModal();
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
        watch: {
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
            modal: {
                handler() {
                    this.initConfirmationModal();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.orderTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.heading {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
}
</style>
